<template>
	<div class="EditPopin">
		<v-card
		flat
		outlined
		>
			<v-card-title
			class="center justify-center"
			align-items
			>
				<h3>Partager des documents</h3>
			</v-card-title>

			<v-expansion-panels v-model="isPanelOpen">
				<v-expansion-panel>
					<v-expansion-panel-header>
						Documents ajoutés ({{ files.length }})
					</v-expansion-panel-header>

					<v-expansion-panel-content>
						<ul>
							<li
							v-for="file in files"
							>
								{{ file.name }}
							</li>
						</ul>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>

			<div
			class="link_created"
			v-if="linkCreated === true"
			>
				<h3>Lien créé avec succès !</h3>

				<br/>

				<v-text-field
				:value="link"
				label="Votre lien de téléchargement"
				outlined
				readonly
				:append-icon="linkCopied ? 'mdi-check' : 'mdi-content-copy'"
				@click:append="toggleCheck"
				/>
			</div>

			<br/>

			<v-card-actions>
				<LowButtonSlot @click="closePopin">
					Annuler
				</LowButtonSlot>

				<v-spacer/>

				<HighButtonSlot
				v-if="linkCreated === false"
				@click="confirmModifications"
				>
					Valider
				</HighButtonSlot>
			</v-card-actions>
		</v-card>
	</div>
</template>

<script>
export default {
	props: ["files"],
	data(){
		return {
			isFormValid: false,
			rules: {
				validEmail: value => {
					if(false === this.newDownloadLink.shouldSendEmail) return true;
					if(value && value.length >= 0){
						const pattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
						return (
							pattern.test(value) || "Veuillez saisir une adresse mail valide"
						);
					}
					else return "Veuillez saisir une adresse mail valide";
				}
			},
			isPanelOpen: true,
			newDownloadLink: {
				documentsIds: this.files.map(file => file.id),
				authorId: this.$store.state.user.current.userId,
				shouldSendEmail: false,
				receiverEmail: null,
				mailContent: null
			},
			users: [],
			link: null,
			linkCopied: false,
			linkCreated: false
		};
	},
	methods: {
		async confirmModifications(){
			const link = await this.$api.documents.generateDownloadLink(
				this.newDownloadLink
			);
			this.link = window.location.origin + "/download?token=" + link.id;
			this.linkCreated = true;
			this.isPanelOpen = false;
		},
		closePopin(){
			this.$emit("closePopin");
		},
		toggleCheck(){
			this.linkCopied = true;

			setTimeout(() => {
				this.linkCopied = false;
			}, 2000);

			navigator.clipboard.writeText(this.link);
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_extends.scss";

.EditPopin {
  @extend %popinView;

  &__card p {
    text-align: left !important;
    margin: 0 0 20px 0 !important;
  }

  .buttonsContainer button {
    margin: 5px;
  }

  .link_created {
    margin-top: 30px;
  }
}
</style>
