<template>
	<v-expansion-panels
	v-model="isOpen"
	class="selectTab"
	v-if="selecting"
	>
		<v-expansion-panel>
			<v-expansion-panel-header class="pt-0 pb-0">
				<h3>Liste des fichiers sélectionnés</h3>
			</v-expansion-panel-header>

			<v-expansion-panel-content>
				<div class="panel">
					<div
					v-for="(file, index) in mergePdfList"
					class="tw-flex tw-justify-between"
					>
						<span class="text tw-flex tw-items-center">
							{{ file.path }}
						</span>
						<div class="tw-flex tw-items-center tw-flex-row">
							<div class="tw-flex tw-items-center tw-flex-col">
								<v-btn
								icon
								@click="moveUp(index)"
								:disabled="index === 0"
								style="height: 20px; width: 20px"
								>
									<v-icon size="small">
										mdi-arrow-up
									</v-icon>
								</v-btn>
								<v-btn
								icon
								@click="moveDown(index)"
								:disabled="index === mergePdfList.length - 1"
								style="height: 20px; width: 20px"
								>
									<v-icon size="small">
										mdi-arrow-down
									</v-icon>
								</v-btn>
							</div>
							<v-btn
							icon
							@click="removeItem(index)"
							>
								<v-icon size="small">
									mdi-close
								</v-icon>
							</v-btn>
						</div>
					</div>
				</div>

				<div class="tw-flex tw-justify-center tw-mt-[10px] tw-gap-[10px]">
					<ButtonSlot
					v-if="mergePdfList.length >= 1 && noImagesSelected"
					@click="zipSelectedFiles"
					class="tw-w-[300px]"
					>
						Créer un ZIP
					</ButtonSlot>
					<ButtonSlot
					v-if="onlyPDFSelect"
					@click="mergePdf"
					class="tw-w-[300px]"
					>
						Fusionner les PDF
					</ButtonSlot>
				</div>
				<div class="tw-flex tw-justify-center tw-mt-[10px] tw-gap-[10px]">
					<ButtonSlot
					v-if="mergePdfList.length >= 1 && $hasRight('files.generateDeliverablesShareLink')"
					@click="downloadLink()"
					class="tw-w-[300px]"
					>
						Lien de partage
					</ButtonSlot>
					
					<ButtonSlot
					@click="clearList()"
					class="tw-w-[300px]"
					>
						Vider la liste
					</ButtonSlot>
				</div>
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>
</template>
  
<script>
import {mapGetters, mapMutations, mapActions} from "vuex";
  
export default {
	name: "SelectTab",
	data(){
		return {
			isOpen: true
		};
	},
	computed: {
	  	...mapGetters("downloader", ["mergePdfList", "selecting"]),
	  	onlyPDFSelect(){
			return this.mergePdfList.length > 1 && !this.mergePdfList.find(f => !f.path.endsWith(".pdf"));
		},
		noImagesSelected(){
			return !this.mergePdfList.find(f => f.path.endsWith("_Photos.zip"));
		},
	},
	watch: {
		mergePdfList(){
			if(this.mergePdfList.length > 0){
				this.SET_SELECTING(true);
			}
			console.log(this.mergePdfList);
		}
	},
	methods: {
		...mapMutations("downloader", [
			"DELETE_FROM_MERGE_PDF_LIST", "ADD_MERGE_PDF_LIST", "CLEAR_MERGE_PDF_LIST", "SET_SELECTING", "SET_DOWNLOAD_LINK_MODAL_OPEN"
		]),
		...mapActions("downloader", [
			"moveUp", "moveDown", "removeItem"
		]),
		clearList(){
			this.CLEAR_MERGE_PDF_LIST();
		},
		async mergePdf(){
			const documentsIds = this.mergePdfList.map(e => e.id);
			const pdf = await this.$api.documents.getMergedPDF(documentsIds);
			const newBlob = new Blob([pdf], {type: "application/pdf"});
			const data = window.URL.createObjectURL(newBlob);
			var link = document.createElement("a");

			link.href = data;
			link.download = "PDF séparé";
			window.open(link, "_blank");
		},

		zipSelectedFiles(){
			let paths = this.mergePdfList.map(e => e.path);
			this.$api.documents
			.zipSelected(paths, this.$route.params.id)
			.then(zipContent => {
				const url = window.URL.createObjectURL(new Blob([zipContent]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", "livrables.zip");
				link.click();
			});
		},
		downloadLink(){
			this.SET_DOWNLOAD_LINK_MODAL_OPEN(true);
		},
		moveUp(index){
			if(index > 0){
				const temp = this.mergePdfList[index - 1];
				this.$set(this.mergePdfList, index - 1, this.mergePdfList[index]);
				this.$set(this.mergePdfList, index, temp);
			}
		},
		moveDown(index){
			if(index < this.mergePdfList.length - 1){
				const temp = this.mergePdfList[index + 1];
				this.$set(this.mergePdfList, index + 1, this.mergePdfList[index]);
				this.$set(this.mergePdfList, index, temp);
			}
		},
	}
	
};
</script>
  
  <style scoped>
  .selectTab {
	position: absolute;
	right: 500px;
	bottom: 0px;
	width: 500px;
	border: solid black 1px;
	border-radius: 4px 4px 0 0;
  }
  .panel {
	min-height: 50px;
	max-height: 150px;
	overflow-y: auto;
	overflow-x: hidden;
  }
  .text {
	max-width: 400px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: pre;
  }
  .arrow {
  cursor: pointer;
  margin-left: 10px;
}
  </style>
  
