<template>
	<div
	class="tw-flex tw-flex-col tw-justify-center tw-rounded-[4px] tw-relative tw-select-none"
	@click="clicked"
	:class="{
		'tw-bg-[rgba(44,7,3,0.1)] ': selected
	}"
	:path="path"
	@contextmenu="$emit('RClick', $event);"
	>
		<v-icon
		class="tw-grow"
		size="100"
		:color="getColor"
		>
			{{ icon }}
		</v-icon>

		<div class="tw-text-center">
			{{ fileName }}
		</div>
		
		<v-tooltip bottom>
			<template v-slot:activator="{ on, attrs }">
				<div
				:path="path"
				v-bind="attrs"
				v-on="on"
				class="tw-absolute tw-top-0 tw-w-full tw-h-full"
				/>
			</template>

			<span>
				{{ fileName }} {{ ", Créé le : " + moment(createdDate).format("DD/MM/Y à H:mm:ss") }} {{ !size ? "" : ", Taille : " + bytesToSize }}
			</span>
		</v-tooltip>
	</div>
</template>

<script>
export default {
	props: [
		"fileName",
		"path",
		"type",
		"selected",
		"size",
		"createdDate"
	],
	data(){
		return {
			numClick: 0,
		};
	},
	computed: {
		icon(){
			if(this.type === "folder") return "mdi-folder";
			else {
				switch (/(?:\.([^.]+))?$/.exec(this.path)[1]?.toLowerCase?.()){
					case "pdf":
						return "mdi-file-pdf-box";
					case "xls":
					case "xlsx":
					case "csv":
						return "mdi-microsoft-excel";
					case "doc":
					case "docx":
						return "mdi-microsoft-word";
					case "jpg":
					case "gif":
					case "jpeg":
					case "png":
						return "mdi-file-image";
					case "zip":
					case "7zip":
						return "mdi-folder-zip";
					case "kml":
						return "mdi-google-earth";
					default:
						return "mdi-file";
				}
			}
		},
		getColor(){
			if(this.type === "folder") return "#e89005";
			else {
				switch (/(?:\.([^.]+))?$/.exec(this.path)[1]?.toLowerCase?.()){
					case "pdf":
						return "#A61009";
					case "xls":
					case "xlsx":
					case "csv":
						return "#55863A";
					case "doc":
					case "docx":
						return "#335C9D";
					case "jpg":
					case "gif":
					case "jpeg":
					case "png":
						return "#A61009";
					case "zip":
					case "7zip":
						return "#e89005";
					case "kml":
						return "#335C9D";
					default:
						return "grey";
				}
			}
		},
		bytesToSize(){
			var sizes = [
				"Bytes", "KB", "MB", "GB", "TB"
			];
			if(this.size == 0) return "0 Byte";
			var i = parseInt(Math.floor(Math.log(this.size) / Math.log(1024)));
			return Math.round(this.size / Math.pow(1024, i), 2) + " " + sizes[i];
		},
	},
	methods: {
		clicked(){
			this.$emit("click");
			this.numClick++;
			if(this.numClick === 1)setTimeout(() => this.numClick = 0, 200);
			else if(this.numClick === 2){
				this.$emit("DClick");
			}
		},
	}
};
</script>

<style>

</style>
