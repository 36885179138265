<template>
	<div class="addFileModale">
		<v-card
		flat
		outlined
		class="addFileModale__card tw-flex tw-flex-col tw-gap-[15px]"
		>
			<h3 class="tw-w-full tw-text-center tw-mb-[15px]">
				{{ this.fName ? 'Renommer le dossier' : 'Créer un dossier' }}
			</h3>

			<v-text-field
			label="Nom du dossier *"
			dense
			autofocus
			outlined
			clearable
			ref="name"
			v-model="folderName"
			/>

			<div class="tw-flex tw-row tw-justify-between">
				<ButtonSlot
				_theme="light-gray"
				@click="closeModal()"
				>
					Annuler
				</ButtonSlot>

				<ButtonSlot @click="createFolder()">
					Valider
				</ButtonSlot>
			</div>
		</v-card>
	</div>
</template>

<script>
export default {
	props: ["fName"],
	data(){
		return {
			folderName: ""
		};
	},
	methods: {
		closeModal(){
			this.$emit("close");
		},
		createFolder(){
			this.fName !== "" ?
				this.$emit("modifyFolder", this.fName, this.folderName) : this.$emit("createFolder", this.folderName);
		}
	},
	mounted(){
		if(this.fName !== ""){
			let test = this.fName.split("/");
			this.folderName = test[test.length - 2];
		}
	}
};
</script>

<style lang="scss" scoped>

@import "@/assets/scss/_extends.scss";

.addFileModale {
  @extend %popinView;

  &__card {
    max-width: 600px;
  }
}
</style>
