<template>
	<section class="coringPdfParams">
		<v-card
		flat
		outlined
		class="coringPdfParams__card"
		id="modale"
		>
			<v-card-title
			class="center justify-center"
			align-items
			>
				<h3>Choix des options de génération du PDF</h3>
			</v-card-title>
			<v-card-text class="card_text">
				<div class="tw-w-full tw-justify-between tw-flex tw-flex-row">
					<v-switch
					v-model="displayAsbestos"
					class="methodology reverse-switch tw-items-center"
					hide-details
					>
						<template #label>
							Afficher le résultat amiante
						</template>
					</v-switch>

					<v-switch
					v-model="displayPAH"
					class="methodology reverse-switch tw-items-center"
					hide-details
					>
						<template #label>
							Afficher le résultat HAP
						</template>
					</v-switch>
				</div>

				<div class="tw-w-full tw-justify-between tw-flex tw-flex-row">
					<v-switch
					v-model="displayTH"
					class="methodology reverse-switch tw-items-center"
					hide-details
					>
						<template #label>
							Afficher le résultat HCT
						</template>
					</v-switch>

					<v-switch
					v-model="displayExactPahResult"
					:disabled="!displayPAH"
					class="methodology reverse-switch tw-items-center"
					hide-details
					>
						<template #label>
							Afficher la valeur HAP exacte
						</template>
					</v-switch>
				</div>

				<div class="tw-w-full tw-justify-between tw-flex tw-flex-row">
					<v-switch
					v-model="displayNewPdfFormat"
					:disabled="displayDirEstFormat"
					class="methodology reverse-switch tw-items-center"
					hide-details
					>
						<template #label>
							Hauteur des lignes dynamique
						</template>
					</v-switch>

					<v-switch
					v-model="displayDirEstFormat"
					:disabled="displayNewPdfFormat"
					class="methodology reverse-switch tw-items-center"
					hide-details
					>
						<template #label>
							Utiliser le format de la DIR EST
						</template>
					</v-switch>
				</div>

				<div class="tw-w-full tw-justify-between tw-flex tw-flex-row">
					<v-switch
					v-model="displayMapLegend"
					class="methodology reverse-switch tw-items-center"
					hide-details
					>
						<template #label>
							Afficher la légende de la carte
						</template>
					</v-switch>

					<v-switch
					v-model="displayDateInterval"
					class="methodology reverse-switch tw-items-center"
					hide-details
					>
						<template #label>
							Afficher l'interval de date
						</template>
					</v-switch>
				</div>
				<div class="tw-w-full tw-justify-between tw-flex tw-flex-row">
					<v-switch
					v-model="displayCustomMap"
					class="methodology reverse-switch tw-items-center"
					hide-details
					>
						<template #label>
							Ajouter une carte d'implantation
						</template>
					</v-switch>
				</div>
				<div
				class="tw-w-full tw-justify-between tw-flex tw-flex-row"
				v-if="displayCustomMap"
				>
					<v-file-input
					type="file"
					accept=".png, .jpg, .jpeg"
					v-model="customMap"
					label="Ajouter une carte d'implantation"
					@change="inputFile"
					:rules="[$rules.requiredIfTrue(displayCustomMap)]"
					hide-details
					/>
				</div>

				<v-img
				label="Photo enregistrée"
				v-if="displayCustomMap && (pictureData || imageUrl)"
				class="logo_image mb-5 tw-relative tw-max-h-[300px]"
				:src="imageUrl ? imageUrl : pictureData"
				contain
				/>

				<div class="tw-w-full tw-justify-between tw-flex tw-flex-row">
					<v-switch
					v-model="displayMultiplePlan"
					class="methodology reverse-switch tw-items-center"
					hide-details
					>
						<template #label>
							Ajouter plusieurs plans
						</template>
					</v-switch>
				</div>
				<div
				class="tw-w-full tw-justify-between tw-flex tw-flex-row"
				v-if="displayMultiplePlan"
				>
					<v-file-input
					type="file"
					accept=".png, .jpg, .jpeg"
					v-model="multipleFiles"
					label="Ajouter plusieurs plans"
					@change="handleFileUpload()"
					multiple
					:rules="[$rules.requiredIfTrue(displayMultiplePlan)]"
					hide-details
					/>
				</div>
				<v-carousel
				v-model="indexPhoto"
				hide-delimiter-background
				show-arrows-on-hover
				height="100%"
				class="tw-mt-[10px]"
				>
					<v-carousel-item
					height="100%"
					v-for="(data, index) in imageUrls"
					:key="index"
					>
						<ButtonSlot
						_icon="mdi-close"
						_width="40"
						class="tw-justify-end"
						@click="removePicture(data)"
						/>
						<v-img
						label="Photo enregistrée"
						class="logo_image mb-5 tw-relative tw-max-h-[300px]"
						:src="data.url"
						contain
						/>
						<v-text-field
						dense
						outlined
						type="text"
						placeholder="Titre de la photo"
						label="Titre de la photo"
						v-model="multipleFiles[index].title"
						/>
					</v-carousel-item>
				</v-carousel>
			</v-card-text>
			<v-card-actions class="tw-flex tw-flex-row tw-gap-[10px] tw-justify-between">
				<ButtonSlot
				_theme="light-gray"
				@click="closePopin()"
				>
					Fermer
				</ButtonSlot>
				<ButtonSlot
				@click="createOrUpdatePdfParams()"
				>
					Valider
				</ButtonSlot>
			</v-card-actions>
		</v-card>
	</section>
</template>

<script>

export default {
	name: "CoringPdfParams",
	data(){
		return {
			campaign: false,
			displayNotExtracted: false,
			displayCancelled: false,
			generateAsbestosPdf: false,
			isSatellitePicture: false,
			displayAsbestos: false,
			displayPAH: false,
			displayTH: false,
			displayCustomMap: false,
			customMap: false,
			displayExactPahResult: false,
			displayNewPdfFormat: false,
			displayDirEstFormat: false,
			pictureData: false,
			imageUrl: false,
			displayMultiplePlan: false,
			multipleFiles: [],
			imageUrls: [],
			indexPhoto: 0,
			displayMapLegend: true,
			displayDateInterval: false
		};
	},
	watch: {
		customMap(){
			if(this.customMap){
				this.createImage(this.customMap);
			}
			else {
				this.imageUrl = false;
			}
		},
	},
	methods: {
		inputFile(e){
			this.customMap = e;
		},
		closePopin(){
			this.$emit("closePopin", false);
		},
		createImage(file){
			const reader = new FileReader();

			reader.onload = e => {
				this.imageUrl = e.target.result;
			};
			reader.readAsDataURL(file);
		},
		async handleFileUpload(){
			this.imageUrls = [];
			const files = this.multipleFiles;
			for(const file of files){
				try {
					const imageUrl = await this.getImage(file);
					this.imageUrls.push(imageUrl);
				}
				catch (error){
					console.error("Erreur lors de la création de l'image:", error);
				}
			}
		},
		async getImage(file){
			return await new Promise((resolve, reject) => {
				const reader = new FileReader();

				reader.onload = e => {
					resolve(e.target.result);
				};

				reader.onerror = e => {
					reject(e);
				};

				reader.readAsDataURL(file);
			});
		},
		createOrUpdatePdfParams(){
			if(this.campaign.pdfParams.length === 0){
				this.$api.pdfParams.create(
					{
						"coringCampaignId": this.campaign.id,
						"displayNotExtracted": this.displayNotExtracted,
						"displayCancelled": this.displayCancelled,
						"generateAsbestosPdf": this.generateAsbestosPdf,
						"isSatellitePicture": this.isSatellitePicture,
						"displayAsbestos": this.displayAsbestos,
						"displayPAH": this.displayPAH,
						"displayTH": this.displayTH,
						"displayCustomMap": this.displayCustomMap,
						"displayExactPahResult": this.displayExactPahResult,
						"isNewPdfFormat": this.displayNewPdfFormat,
						"isDirEstPdfFormat": this.displayDirEstFormat,
						"isDisplayMultiplePlanPDF": this.displayMultiplePlan,
						"isDisplayMapLegend": this.displayMapLegend,
						"isDisplayDateInterval": this.displayDateInterval
					}
				).then(async() => {
					if(this.customMap) await this.uploadImage();
					if(this.multipleFiles.length !== 0) await this.uploadMultipleFiles();
					this.$emit("validated", true);
					this.closePopin();
				});
			}
			else {
				this.$api.pdfParams.update(
					this.campaign.pdfParams[0].id,
					{
						"displayNotExtracted": this.displayNotExtracted,
						"displayCancelled": this.displayCancelled,
						"generateAsbestosPdf": this.generateAsbestosPdf,
						"isSatellitePicture": this.isSatellitePicture,
						"displayAsbestos": this.displayAsbestos,
						"displayPAH": this.displayPAH,
						"displayTH": this.displayTH,
						"displayCustomMap": this.displayCustomMap,
						"displayExactPahResult": this.displayExactPahResult,
						"isNewPdfFormat": this.displayNewPdfFormat,
						"isDirEstPdfFormat": this.displayDirEstFormat,
						"isDisplayMultiplePlanPDF": this.displayMultiplePlan,
						"isDisplayMapLegend": this.displayMapLegend,
						"isDisplayDateInterval": this.displayDateInterval
					}
				).then(async() => {
					if(this.customMap) await this.uploadImage();
					if(this.multipleFiles.length !== 0) await this.uploadMultipleFiles();
					this.$emit("validated", true);
					this.closePopin();
				});
			}
		},
		async uploadImage(){
			var formData = new FormData();
			formData.append("file", this.customMap);
			formData.append("campaignId", this.campaign.id);
			formData.append("category", "pdfMap");
			let fileName = this.customMap.name.split(".")[0] + "." + this.customMap.name.split(".")[1].toLowerCase();
			formData.append("pathFile", fileName);

			var extension = /(?:\.([^.]+))?$/.exec(this.customMap.name)[1].toLowerCase();

			await this.$api.documents.upload(formData, {
				name: this.customMap.name,
				extension: extension,
				size: this.customMap.size,
				category: "pdfMap",
				campaignId: this.campaign.id,
				requiredDocumentId: null,
			});
		},
		async uploadMultipleFiles(){
			try {
				const documents = await this.$api.documents.uploadMultiple(this.multipleFiles, this.campaign.id);
				console.log("Documents uploadés avec succès:", documents);
			}
			catch (error){
				console.error("Erreur lors de l'upload des documents:", error);
			}
		},
		clearForm(){
			this.campaign = false;
			this.displayNotExtracted = false;
			this.displayCancelled = false;
			this.generateAsbestosPdf = false;
			this.isSatellitePicture = false;
			this.displayAsbestos = false;
			this.displayPAH = false;
			this.displayTH = false;
			this.displayCustomMap = false;
			this.customMap = false;
			this.displayExactPahResult = false;
			this.displayNewPdfFormat = false;
			this.displayDirEstFormat = false;
			this.displayMultiplePlan = false;
			this.displayMapLegend = false;
			this.displayDateInterval = false;
		},
		setValues(){
			this.displayNotExtracted = false;
			this.displayCancelled = false;
			this.generateAsbestosPdf = false;
			this.isSatellitePicture = false;
			this.displayAsbestos = this.campaign.pdfParams[0].displayAsbestos;
			this.displayPAH = this.campaign.pdfParams[0].displayPAH;
			this.displayTH = this.campaign.pdfParams[0].displayTH;
			this.displayExactPahResult = this.campaign.pdfParams[0].displayExactPahResult;
			this.displayNewPdfFormat = this.campaign.pdfParams[0].isNewPdfFormat;
			this.displayDirEstFormat = this.campaign.pdfParams[0].isDirEstPdfFormat;
			this.displayCustomMap = this.campaign.pdfParams[0].displayCustomMap;
			this.displayMultiplePlan = this.campaign.pdfParams[0].displayMultiplePlanPDF;
			this.displayMapLegend = this.campaign.pdfParams[0].displayMapLegend;
			this.displayDateInterval = this.campaign.pdfParams[0].displayDateInterval;

			if(this.displayCustomMap){
				this.getCustomMapPreview();
			}
			if(this.displayMultiplePlan){
				this.getMultiplePlanPreview();
			}
		},
		async getCustomMapPreview(){
			this.$api.pdfParams.getPicture(this.campaign.pdfParams[0].id, "pdfMap").then(data => {
				data.forEach(url => {
					this.pictureData = url["pre-signed-url"];
				});
			});
		},
		async getMultiplePlanPreview(){
			this.$api.pdfParams.getPicture(this.campaign.pdfParams[0].id, "multiplePlanPDF").then(data => {
				data.forEach(url => {
					this.multipleFiles.push({id: url["id"], title: url["title"]});
					this.imageUrls.push({url: url["pre-signed-url"], id: url["id"]});
				}
				);
			});
		},
		removePicture(document){
			this.$api.documents.deleteFile(document.id);
			this.multipleFiles = [];
			this.imageUrls = [];
			this.getMultiplePlanPreview();
		}
	},
	async mounted(){
		this.campaign = await this.$api.coringCampaigns.findById(this.$route.params.id);
		this.setValues();
	},
};
</script>

<style lang="scss">
@import "@/assets/scss/_extends.scss";

.coringPdfParams {
  @extend %popinView;

  .v-card {
    width: 40%;
    text-align: center;
    padding: 20px 20px;
    max-height: 90%;
    overflow: auto;
  }

}
</style>
