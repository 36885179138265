<template>
	<v-card
	class="tw-flex tw-flex-col tw-gap-[10px] tw-p-[20px] tw-relative"
	outlined
	@dragover="dropOverToggle"
	@contextmenu="openMenu($event, 'background')"
	>
		<CreateDownloadLinkModale
		class="tw-z-[2]"
		:files="shareLinkFiles"
		v-if="downloadLinkModalOpen"
		@closePopin="SET_DOWNLOAD_LINK_MODAL_OPEN(false)"
		/>

		<div
		v-if="showAddDocument"
		class="tw-fixed tw-top-0 tw-left-0 tw-bg-[rgba(0,0,0,0.2)] tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center tw-z-10"
		>
			<v-card class="tw-w-[500px]">
				<v-card-title> Ajouter de nouveaux documents </v-card-title>

				<v-card-text>
					Le document sera ajouté à cet emplacement :
					<v-chip>{{ currentFolder.path }}</v-chip>
				</v-card-text>

				<v-form
				ref="sendDocumentForm"
				@submit.prevent="uploadFile([...sendedFiles]); showAddDocument = false; sendedFiles = []"
				>
					<v-card-text>
						<v-file-input
						v-model="sendedFiles"
						label="Ajoutez un ou plusieurs fichiers"
						show-size
						truncate-length="15"
						multiple
						/>
					</v-card-text>

					<v-divider/>

					<v-card-actions>
						<medium-button-slot
						color="primary"
						text
						@click="showAddDocument = false"
						>
							Annuler
						</medium-button-slot>

						<v-spacer/>

						<ButtonSlot
						:_disabled="sendedFiles.length === 0"
						:_type="'submit'"
						>
							Envoyer
						</ButtonSlot>
					</v-card-actions>
				</v-form>
			</v-card>
		</div>

		<div
		v-if="dropOver && $hasRight('files.uploadDeliverables')"
		class="tw-w-full tw-h-full tw-absolute tw-top-0 tw-left-0 tw-bg-[rgba(255,255,255,0.3)] tw-z-[1] tw-flex tw-justify-center tw-items-center tw-p-[20px]"
		>
			<div class="tw-w-full tw-h-full tw-border-gray-300 tw-border-dashed tw-flex tw-justify-center tw-items-center">
				<v-icon size="100px">
					mdi-plus
				</v-icon>
			</div>

			<input
			class="tw-w-full tw-h-full tw-absolute tw-bg-transparent tw-top-0 tw-left-0 tw-color-transparent tw-outline-none tw-caret-transparent"
			@drop="dropFile"
			/>
		</div>
	
		<div
		v-if="menu"
		class="tw-fixed tw-bg-[white] tw-z-10 tw-w-[200px] tw-flex tw-flex-col tw-select-none tw-rounded-[4px] tw-border-[1px] tw-border-solid tw-border-[lightgrey]"
		@click="$event.stopPropagation()"
		:style="{
			top: menu.y + 'px',
			left: menu.x + 'px',
		}"
		>
			<div
			class="tw-w-full hoverDiv tw-p-[10px]"
			@click="selectAll"
			>
				Tout sélectionner
			</div>

			<div
			v-if="menu.type === 'background'"
			class="tw-w-full hoverDiv tw-p-[10px]"
			@click="newFolderName = '';addingFolder = true; menu = false"
			>
				Créer un dossier
			</div>

			<div
			v-if="menu.type === 'folder'"
			class="tw-w-full hoverDiv tw-p-[10px]"
			@click="currentFolders.push(menu); menu = null"
			>
				Accéder au dossier
			</div>

			<div
			v-if="false"
			class="tw-w-full hoverDiv tw-p-[10px]"
			@click=""
			>
				Supprimer
			</div>

			<div
			v-if="selectFiles.length === 1 && menu.type === 'file'"
			class="tw-w-full hoverDiv tw-p-[10px]"
			@click="splitPdf"
			>
				Séparer le PDF
			</div>

			<div
			v-if="menu.type === 'file' && onlyFile && noImagesSelected && hasId"
			class="tw-w-full hoverDiv tw-p-[10px]"
			@click="addToSelection"
			>
				Ajouter à la sélection
			</div>

			<div
			v-if="menu.type === 'file' && selectFiles.length === 1 && onlyFile"
			class="tw-w-full hoverDiv tw-p-[10px]"
			@click="downloadFile(menu.path)"
			>
				Télécharger
			</div>

			<div
			v-if="menu.type === 'folder' && selectFiles.length === 1 && menu.insideCount === 0"
			class="tw-w-full hoverDiv tw-p-[10px]"
			@click="newFolderName = menu.path; menu=false; addingFolder = true; "
			>
				Renommer
			</div>

			<div
			v-if="menu.type === 'file' && onlyFile && $hasRight('campaigns.deleteFile')"
			class="tw-w-full hoverDiv tw-p-[10px]"
			@click="deleteFile"
			>
				Supprimer le fichier
			</div>
		</div>

		<div
		v-if="folder !== null"
		class="tw-flex tw-gap-[10px] tw-items-center tw-select-none"
		>
			<v-icon
			class="tw-text-[black]"
			size="30"
			:disabled="currentFolders.length === 1"
			@click="currentFolders.pop()"
			>
				mdi-arrow-left-thick
			</v-icon>

			<span class="tw-grow tw-overflow-hidden italic">
				<v-icon color="#e89005">
					mdi-folder
				</v-icon> {{ "Livrables > " + currentFolder.path.replaceAll('/', ' > ') }}
			</span>

			<div class="tw-w-[300px]">
				<v-text-field
				label="Rechercher un fichier..."
				v-model="search"
				dense
				clearable
				hide-details
				/>
			</div>

			<ButtonSlot
			v-if="$hasRight('files.uploadDeliverables')"
			@click="showAddDocument = true"
			_theme="light-gray"
			>
				Ajouter des documents
			</ButtonSlot>
		</div>

		<div class="tw-bg-[rgba(0,0,0,0.2)] tw-w-full tw-h-[1px] tw-mt-[10px]"/>

		<div
		class="tw-overflow-hidden tw-flex tw-flex-wrap tw-items-start tw-gap-[15px]"
		ref="filesDiv"
		v-if="currentFolder !== null"
		>
			<File
			v-for="[folderName, infos] of Object.entries(currentFolder.folders)"
			:key="infos.path"
			class="tw-aspect-[9/10] tw-w-[100px]"
			@click="leftClickFile(infos.path)"
			@DClick="currentFolders.push(infos)"
			@RClick="openMenu($event, 'folder', infos)"
			:selected="selectFiles.indexOf(infos.path) !== -1"
			:path="infos.path"
			:fileName="folderName"
			type="folder"
			/>

			<AddFile
			v-if="addingFolder"
			:fName="newFolderName"
			@close="addingFolder= false"
			@createFolder="addFolder"
			@modifyFolder="modifyFolder"
			/>

			<File
			v-for="[fileName, infos] of Object.entries(currentFolder.files)"
			class="tw-aspect-[9/10] tw-w-[100px]"
			@click="leftClickFile(infos.path)"
			@DClick="downloadFile(infos.path, infos.s3Path)"
			@RClick="openMenu($event, fileName.includes('_Photos') ? 'not-a-file' : 'file', infos)"
			:selected="selectFiles.indexOf(infos.path) !== -1"
			:path="infos.path"
			:fileName="fileName"
			:size="infos.size"
			:createdDate="infos.createdAt"
			type="file"
			/>
		</div>
	</v-card>
</template>

<script>
import AddFile from "../fileManager/addFile.vue";
import File from "../fileManager/file.vue";
import CreateDownloadLinkModale from "@/components/entities/file/CreateDownloadLink.modal.vue";
import {mapGetters, mapActions, mapMutations} from "vuex";

export default {
	name: "FileManager",
	components: {
		File,
		CreateDownloadLinkModale,
		AddFile
	},
	data(){
		return {
			campaign: null,
			folder: null,
			currentFolders: [],
			selectFiles: [],
			pressedKey: {
				"Shift": false,
				"Control": false,
			},
			s3path: null,
			dropOver: false,
			dropOverTime: 0,
			files: [],
			menu: null,
			addingFolder: false,
			newFolderName: "",
			search: "",
			showDownloadLinkModale: false,
			showAddDocument: false,
			sendedFiles: [],
			hasId: false
		};
	},
	computed: {
		...mapGetters("downloader", [
			"downloading", "downloadList", "mergePdfList", "downloadLinkModalOpen"
		]),
		currentFolder(){
			return this.currentFolders[this.currentFolders.length - 1] || null;
		},
		onlyFile(){
			return !this.selectFiles.find(f => f.endsWith("/"));
		},
		noImagesSelected(){
			return !this.selectFiles.find(f => f.endsWith("_Photos.zip"));
		},
		shareLinkFiles(){
			return this.mergePdfList.map(p => ({id: this.files.find(f => f.path === p.path).id, name: p.path.split("/").reverse()[0]})).filter(f => !!f.id);
		}
	},
	watch: {
		currentFolders(){
			this.selectFiles = [];
		}, 
		files(){
			this.setFolders(this.files);
		},
		search(){
			if(this.search){
				this.currentFolders = [];
				this.setFolders(
					this.files.filter(f => {
						let name = f.path.split("/").pop() || false;
						if(name && name.indexOf(this.search) !== -1) return true;
						else return false;
					})
					.map(f => {
						return {
							path: f.path.split("/").pop(),
							size: f.size,
						};
					})
				);
			}
			else this.setFolders(this.files);
		},
		selectFiles(){
			let test = false;
			this.selectFiles.forEach(filename => {
				let test2 = this.files.find(f => f.path === filename);
				if(test2 !== undefined){
					if(test2.id){
						test = true;
					}
					else {
						test = false;
					}
				}
			});

			this.hasId = test;
		}
	},
	methods: {
		test(value){
			console.log(value);
		},
		...mapActions("downloader", [
			"setDownloading", "setDownloadList", "deleteFromDownloadList", "setDownloadStatus"
		]),
		...mapMutations("downloader", [
			"DELETE_FROM_MERGE_PDF_LIST", "ADD_MERGE_PDF_LIST", "CLEAR_MERGE_PDF_LIST", "SET_DOWNLOAD_LINK_MODAL_OPEN"
		]),
		async getFiles(){
			const [result, infos] = await Promise.all([
				this.$api.documents.findDeliveryByCampaign(this.campaign.id),
				this.$api.documents.findByCampaign(this.campaign.id, "delivery")
			]);
			this.s3path = result.s3path;
			this.files = result.files.map(f => {
				f.id = infos.find(i => i.s3Path === result.s3path + f.path)?.id;
				f.createdAt = infos.find(i => i.s3Path === result.s3path + f.path)?.createdAt;
				return f;
			});
			if(
				(this.campaign.status !== "awaiting_validation" ||
				this.campaign.status !== "programing_validated") &&
				this.campaign.type === "coring"
			) this.files.push({
				fileName: this.campaign.name.replaceAll(" ", "_") + "_Photos.zip",
				size: 12333342,
				extension: "zip",
				path: this.campaign.name.replaceAll(" ", "_") + "_Photos.zip",
				s3Path: "[[all_pictures]]_" + this.$route.params.id
			});
		},

		async setFolders(files){
			const folder = {
				path: "",
				files: {},
				folders: {}
			};

			files.forEach(file => {
				let currentFolder = folder;
				const path = file.path.split("/");
				let fileName = path.pop();
				path.forEach(folder => {
					if(!currentFolder.folders[folder]){
						currentFolder.folders[folder] = {
							path: currentFolder.path + folder + "/",
							files: {},
							folders: {}
						};
					}
					currentFolder = currentFolder.folders[folder];
				});

				if(fileName)currentFolder.files[fileName] = file;
			});

			this.folder = folder;

			//this.currentFolders = [folder];

			if(this.currentFolders.length < 2) this.currentFolders = [folder];
			else {
				let currentFolder = folder;
				this.currentFolders[0] = currentFolder;
				const path = this.currentFolder.path.split("/");
				path.forEach((name, index) => {
					if(!name) return; 
					this.currentFolders[index + 1] = currentFolder.folders[name];
				});
				this.currentFolders = [...this.currentFolders];
			}

			this.$forceUpdate();
		},

		downloadFile(path, s3Path = null){
			this.setDownloading(true);
			this.setDownloadList([...this.downloadList, {name: path, downloadState: "loading"}]);
			this.menu = null;
			let doc;
			if(s3Path !== null && s3Path.startsWith("[[all_pictures]]")){
				this.$api.documents.download(s3Path).then(e => {
					doc = e;
					const url = window.URL.createObjectURL(new Blob([doc]));
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", path.split("/").reverse()[0]);
					link.click();
					this.setDownloadStatus({downloadState: "finished", element: path});
				}).catch(error => {
					console.log(error);
					this.setDownloadStatus({downloadState: "failed", element: path});
				});
			}
			else {
				this.$api.documents.download(this.s3path + path).then(e => {
					doc = e;
					const url = window.URL.createObjectURL(new Blob([doc]));
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", path.split("/").reverse()[0]);
					link.click();
					this.setDownloadStatus({downloadState: "finished", element: path});
				}).catch(error => {
					console.log(error);
					this.setDownloadStatus({downloadState: "failed", element: path});
				});
			}
		},

		async splitPdf(){
			this.setDownloading(true);
			this.menu = null;
			const documentId = this.selectFiles.map(path => this.files.find(f => f.path === path).id).filter(id => id !== undefined);
			let zipName = this.campaign.name.replaceAll(" ", "_") + "_PV_Analyses_Amiante.zip";
			this.setDownloadList([...this.downloadList, {name: zipName, downloadState: "loading"}]);
			this.$api.documents.getSplitedPDF(documentId[0]).then(zipContent => {
				const url = window.URL.createObjectURL(new Blob([zipContent]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", zipName);
				link.click();
				this.setDownloadStatus({downloadState: "finished", element: zipName});
			}).catch(error => {
				console.log(error);
				this.setDownloadStatus({downloadState: "failed", element: zipName});
			});
		},

		async deleteFile(){
			this.menu = null;
			const documentId = this.selectFiles.map(path => this.files.find(f => f.path === path).id).filter(id => id !== undefined);
			this.$api.documents.deleteFile(documentId[0]).then(() => {
				this.getFiles();
			});
		},

		dropFile(e){
			this.menu = null;
			e.preventDefault();
			this.uploadFile([...e.dataTransfer.files]);
		},

		uploadFile(files){
			files.forEach(documentFile => {
				var formData = new FormData();
				formData.append("file", documentFile);
				formData.append("campaignId", this.$route.params.id);
				formData.append("category", "delivery");
				formData.append("pathFile", this.currentFolder.path + documentFile.name);

				var extension = /(?:\.([^.]+))?$/.exec(documentFile.name)[1];
				this.$api.documents.upload(formData, {
					name: documentFile.name,
					extension: extension,
					size: documentFile.size,
					category: "delivery",
					campaignId: this.campaign.id,
					requiredDocumentId: null,
				})
				.then(() => {
					this.getFiles();
				});
			});
		},

		dropOverToggle(){
			this.dropOverTime = Date.now();
			if(!this.dropOver){
				const interval = setInterval(() => {
					if(this.dropOverTime + 100 < Date.now()){
						clearInterval(interval);
						this.dropOver = false;
					}
				}, 50);
				this.dropOver = true;
			}
		},

		leftClickFile(path){
			if(this.pressedKey.Control && this.selectFiles.indexOf(path) === -1) this.selectFiles.push(path);
			else if(this.pressedKey.Control && this.selectFiles.indexOf(path) !== -1) this.selectFiles = this.selectFiles.filter(f => f !== path);
			else if(this.pressedKey.Shift){
				this.selectFiles.push(path);
				let filesDiv = [...this.$refs.filesDiv.children];
				let indexFirst = filesDiv.findIndex(f => f.getAttribute("path") == this.selectFiles[0]);
				let indexLast = filesDiv.findIndex(f => f.getAttribute("path") == path);
				if(indexFirst > indexLast){
					let temp = indexLast;
					indexLast = indexFirst;
					indexFirst = temp;
				}

				filesDiv.forEach((f, index) => {
					if(index < indexLast && index > indexFirst) this.selectFiles.push(f.getAttribute("path"));
				});
			}
			else this.selectFiles = [path];
			this.menu = null;
		},

		openMenu(e, type, info = null){ 
			e.preventDefault();
			e.stopPropagation();
			if(info !== null){
				if(info.path && this.selectFiles.indexOf(info.path) === -1) this.selectFiles = [info.path];
			}
			this.menu = {
				x: e.clientX,
				y: e.clientY,
				type
			};
			info !== null ? this.menu.path = info.path : "";
			info !== null ? this.menu.folders = info.folders : "";
			info !== null ? this.menu.files = info.files : "";

			type === "folder" ? this.menu.insideCount = Object.entries(info.folders).length +  Object.entries(info.files).length : "";
		},

		addFolder(folderName){
			if(this.addingFolder === false) return;
			this.addingFolder = false;
			if(folderName === "") return;
			let path = this.currentFolders[this.currentFolders.length - 1].path + folderName + "/";
			this.files.push({
				path: path,
				size: 0
			});
			this.$api.documents.createFolder(this.$route.params.id, {path: path}).then(() => {
				this.getFiles();
			});
			this.menu = null;

			return false;
		},

		modifyFolder(oldFolderName, newFolderName){
			this.addingFolder = false;
			let newPath = this.currentFolders[this.currentFolders.length - 1].path + newFolderName + "/";
			this.$api.documents.modifyFolder(this.$route.params.id, {oldPath: oldFolderName, newPath: newPath}).then(() => {
				this.getFiles();
			});

			return false;
		},
		selectAll(){
			this.menu = null;
			this.selectFiles = [];
			[...this.$refs.filesDiv.children].forEach((f) => this.selectFiles.push(f.getAttribute("path")));
		},

		keyDown(e){
			if(this.pressedKey[e.key] !== undefined) this.pressedKey[e.key] = true;
		},
		keyUp(e){
			if(this.pressedKey[e.key] !== undefined) this.pressedKey[e.key] = false;
		},
		clickWindow(e){
			let path = e.target.getAttribute("path");
			if(!path){
				if(this.showDownloadLinkModale === false) this.selectFiles = [];
				this.menu = null;
			}
		},
		addToSelection(){
			let documents = this.files.filter(item => this.selectFiles.includes(item.path)).map(item => ({path: item.path, id: item.id}));

			if(documents !== undefined){
				documents.forEach(document => {
					if(undefined === this.mergePdfList.find(item => item.id === document.id)){
						this.ADD_MERGE_PDF_LIST({id: document.id, path: document.path});
					}
				});
			}
			//const documentId = this.selectFiles.map(path => this.files.find(f => f.path === path).id).filter(id => id !== undefined);
			//this.ADD_MERGE_PDF_LIST(documentId);
		}
	},
	async mounted(){
		this.campaign = await this.$api.campaigns.findById(this.$route.params.id);
		this.getFiles();

		window.addEventListener("keydown", this.keyDown);
		window.addEventListener("keyup", this.keyUp);
		window.addEventListener("click", this.clickWindow);
	},
	unmounted(){
		window.removeEventListener("keydown", this.keyDown);
		window.removeEventListener("keyup", this.keyUp);
		window.removeEventListener("click", this.clickWindow);
	}
};
</script>

<style lang="scss" scoped>
.hoverDiv:hover {
	background-color: lightgrey;
}
.italic {
	font-style: italic;
}
</style>
