<template>
	<div
	class="tw-h-full tw-p-[24px] tw-flex tw-flex-col tw-gap-[10px]"
	>
		<CoringPdfParamsPopup
		v-show="displayPdfParams"
		@closePopin="displayPdfParams = false"
		@validated="refreshData()"
		:campaign="campaign"
		/>

		<HeaderSlot
		:title="$cap(campaign.name)"
		subTitle="Livrables"
		@clickBackButton="$router.back()"
		>
			<ButtonSlot
			v-if="
				$hasRight('files.uploadDeliverables') && campaign.type === 'coring'
			"
			style="float: right"
			@click="regeneratePdf"
			:_disabled="pdfIsGenerating || campaign.isNotRefreshableProvider === true"
			:_tooltip="campaign.isNotRefreshableProvider === true ? 'Ce bouton à été désactivé car une action manuelle à été réalisée sur cette campagne. Contactez un administrateur' : ''"
			_icon="mdi-refresh"
			>
				Regénérer le livrable
			</ButtonSlot>

			<ButtonSlot
			@click="displayPdfParams = !displayPdfParams"
			:_res="1150"
			_icon="mdi-cog-outline"
			_theme="light-gray"
			>
				Options PDF
			</ButtonSlot>

			<ButtonSlot
			@click="exportAllCores"
			:_loading="zipLoading"
			:_disabled="zipLoading"
			_theme="light-gray"
			v-if="campaign.type === 'coring'"
			_icon="mdi-file-pdf-box"
			>
				Livrables par carottes
			</ButtonSlot>

			<ButtonSlot
			@click="exportCsv()"
			_icon="mdi-database-export"
			_theme="light-gray"
			>
				Livrables CSV
			</ButtonSlot>

			<ButtonSlot 
			@click="handleClick()"
			_icon="mdi-file-document-outline"
			>
				Accéder à la campagne
			</ButtonSlot>
		</HeaderSlot>

		<v-alert
		dense
		type="info"
		v-if="pdfIsGenerating"
		>
			Vos documents sont en cours de génération, cette opération peut durer
			plusieurs minutes. Merci de patienter.
		</v-alert>

		<FileManager class="w-full tw-grow"/>

		<CreateDownloadLinkModale
		:files="download.selected"
		@newLink="displayNewLink"
		@closePopin="download.showDownloadModale = false"
		v-if="download.showDownloadModale"
		/>

		<v-snackbar
		vertical
		timeout="8000"
		v-model="linkSnackbar.shouldDisplay"
		>
			<p v-if="!linkSnackbar.copied">
				Lien de téléchargement créé ! <br/>

				<a
				ref="downloadLink"
				:href="linkSnackbar.link"
				>{{
					linkSnackbar.link
				}}</a>
			</p>

			<p v-else>
				Lien copié dans le presse papier !
			</p>

			<template v-slot:action="{ attrs }">
				<MediumButtonSlot
				v-if="!linkSnackbar.copied"
				v-bind="attrs"
				@click="copyTextToClipboard()"
				>
					Copier
				</MediumButtonSlot>

				<MediumButtonSlot
				v-else
				v-bind="attrs"
				@click="linkSnackbar.shouldDisplay = false"
				>
					Fermer
				</MediumButtonSlot>
			</template>
		</v-snackbar>

		<SelectTab/>
	</div>
</template>

<script>
import CreateDownloadLinkModale from "@/components/entities/file/CreateDownloadLink.modal.vue";
import FileManager from "../../components/campaign/FileManager.vue";
import CoringPdfParamsPopup from "../../components/coring/CoringPdfParams.popup.vue";
import {mapGetters, mapActions} from "vuex";
import SelectTab from "../../components/fileManager/SelectTab.vue";

export default {
	name: "ProjectFiles",
	components: {
		CreateDownloadLinkModale,
		FileManager,
		CoringPdfParamsPopup,
		SelectTab
	},
	data(){
		return {
			linkSnackbar: {
				shouldDisplay: false,
				link: "",
				copied: false
			},
			isMergeOptionActive: false,
			isGenerateLinkOptionActive: false,
			loading: true,
			campaign: {},
			search: "",
			isSelecting: false,
			download: {
				selected: [],
				showDownloadModale: false
			},
			documentFiles: [],
			isDocumentUploading: false,
			headers: [],
			documents: [],
			numberedDocuments: [],
			rules: [v => !!v || "Une valeur est requise"],
			zipLoading: false,
			pdfIsGenerating: false,
			displayPdfParams: false
		};
	},
	computed: {
		...mapGetters("downloader", ["downloading", "downloadList"]),
		tableElements(){
			return this.isMergeOptionActive
				? this.numberedDocuments.filter(d => "pdf" === d.extension)
				: this.numberedDocuments;
		},
		tableHeaders(){
			let headers = [
				{text: "Nom", value: "name"},
				{text: "Date de création", value: "createdAt"},
				{text: "Taille du fichier", value: "size"},
				{text: "Télécharger", value: "download"}
			];

			if(this.isSelecting && this.isMergeOptionActive) headers.splice(0, 0, {text: "Ordre (PDF fusionné)", value: "number"});

			return headers;
		}
	},
	watch: {
		isSelecting(){
			if(this.isSelecting === false) this.download.selected = [];
		},
		// reset numbers on documents change
		documents(){
			this.numberedDocuments = this.documents.map(d => {
				return {...d, number: 0};
			});
		},
		"download.selected": {
			deep: true,
			handler(newSelected, oldSelected){
				if(newSelected.length < oldSelected.length) this.handleRemove();
				else {
					let newId = 0;

					for(let i = 0; i < newSelected.length; i++){
						const elem = newSelected[i];
						if(-1 === oldSelected.findIndex(d => d.id === elem.id)) newId = elem.id;
					}

					this.handleNewSelection(newId);
				}
			}
		},
	},
	methods: {
		...mapActions("downloader", [
			"setDownloading", "deleteFromDownloadList", "setDownloadList", "setDownloadStatus"
		]),
		...mapActions("coring", ["setCampaign"]),
		async refreshData(){
			await this.$api.coringCampaigns.getFromProvider(this.$route.params.id);
			await this.setCampaign(this.$route.params.id);
		},
		isSelected(documentId){
			return -1 !== this.download.selected.findIndex(d => d.id === documentId);
		},
		handleNewSelection(newId){
			// on new selection, number to attribute is current last number + 1
			let max = 0;
			for(let i = 0; i < this.download.selected.length; i++){
				const document = this.download.selected[i];
				if(document.number > max) max = document.number;
			}

			const doc = this.numberedDocuments.find(d => d.id === newId);

			if(undefined !== doc) doc.number = max + 1;
		},
		handleRemove(){
			let selected = [...this.download.selected];
			selected.sort((a, b) => a.number - b.number);

			for(let i = 0; i < selected.length; i++){
				let current = selected[i];

				if(i + 1 !== current.number) current.number = i + 1;
			}

			for(let i = 0; i < this.numberedDocuments.length; i++){
				const doc = this.numberedDocuments[i];
				if(false === this.isSelected(doc.id)) doc.number = 0;
			}
		},
		async getCampaign(){
			this.campaign = await this.$api.campaigns.findById(this.$route.params.id);
		},
		displayNewLink(link){
			this.linkSnackbar.copied = false;
			this.linkSnackbar.link = link;
			this.linkSnackbar.shouldDisplay = true;
			this.download.showDownloadModale = false;
		},
		fallbackCopyTextToClipboard(){
			this.$refs.downloadLink.focus();
			this.$refs.downloadLink.select();

			try {
				var successful = document.execCommand("copy");
				var msg = successful ? "successful" : "unsuccessful";
				console.log("Fallback: Copying text command was " + msg);
				this.linkSnackbar.copied = true;
			}
			catch (err){
				console.error("Fallback: Oops, unable to copy", err);
			}
		},
		handleClick(){
			if(this.campaign.type === "auscultation"){
				this.$router.push({name: "auscultation-campaign-dashboard", params: {id: this.campaign.id}});
			}
			else {
				if(this.campaign.status === "results_obtained" || (this.campaign.status === "sent_for_analysis" && this.campaign.hasAsbestos)){
					this.$router.push({name: "coring-campaign-dashboard", params: {id: this.campaign.id}});
				}
				else {
					this.$router.push({name: "coring-campaign", params: {id: this.campaign.id}});
				}
				
			}
		},
		async exportAllCores(){
			let fileName = "LivrablesMaorie_" + this.campaign.name.replaceAll(" ", "_") + "_ParCarotte.zip";
			this.setDownloading(true);
			this.setDownloadList([...this.downloadList, {name: fileName, downloadState: "loading"}]);
			this.zipLoading = true;
			this.$api.documents.getAllCoresAsPdf(
				this.$route.params.id
			).then(zip => {
				const newBlob = new Blob([zip], {type: "application/zip"});
				const data = window.URL.createObjectURL(newBlob);
				var link = document.createElement("a");
				link.href = data;
				link.download = "Exportation des carottes";
				this.zipLoading = false;
				window.open(link, "_blank");
				this.setDownloadStatus({downloadState: "finished", element: fileName});
			}).catch(error => {
				console.log(error);
				this.setDownloadStatus({downloadState: "failed", element: fileName});
			});
		},
		regeneratePdf(){
			this.pdfIsGenerating = true;
			this.$api.coringCampaigns
			.getFromProvider(this.$route.params.id, true)
			.then(() => {
				this.getFiles(this.$route.query.path);
			});
		},
		exportCsv(){
			let zipName = this.campaign.name.replaceAll(" ", "_") + "_CSV.zip";
			this.setDownloading(true);
			this.setDownloadList([...this.downloadList, {name: zipName, downloadState: "loading"}]);

			this.$api.documents
			.exportCsvZip(this.campaign.id)
			.then(documentContent => {
				const url = window.URL.createObjectURL(new Blob([documentContent]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", zipName);
				link.click();
				this.setDownloadStatus({downloadState: "finished", element: zipName});
			}).catch(error => {
				console.log(error);
				this.setDownloadStatus({downloadState: "failed", element: zipName});
			});
		},
	},
	async mounted(){
		await this.getCampaign();
	}
};
</script>

<style lang="scss">
#project-files {
  height: 100%;
}

.rotating-icon {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.v-data-footer {
  &__select {
    visibility: hidden !important;
  }
  &__pagination {
    visibility: hidden !important;
  }
}

.bordered {
  border: thin solid rgba(0, 0, 0, 0.12);
}
</style>
